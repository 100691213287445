.dashboard-card-header{
    background-color: #ffffff;
    /* margin-bottom: 20px; */
}

.dashboard-card-header span{
    line-height: 60px;
    width: 95%;
    padding: 0 30px;
    font-weight: 600;
    color: #242e4c;
    font-size: 16px;
    
}
.slider-row{


    box-shadow:  0 10px 40px 0 rgba(18, 106, 211, .07), 0 2px 9px 0 rgba(18, 106, 211, .06);

}
.slider-container{
    background-color: #edf1f8;
    margin-bottom: 50px;
  
    padding: 15px 50px;
    height: 240px;
    /* text-align: center; */
}


.image-card{
    background-color: #fff;
    height: 300px;
    padding: 20px 30px;
}

.bundles-img{
   
    position: relative;
    width: 150px;
    margin: 0 auto;
    height: 150px;
}


.quickLinkBox{
    padding: 10px 5px; width: 100%; display: inline-block;margin-right: 20px;
}


.quickLinkCard {
    width: 190px;
    height: 170px;
    border-radius: 3px;
    padding: 20px;
    cursor: pointer;
    transition: background 900ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    background-color: #fff;
    border: none!important;
    border-radius: .25rem;
    margin-right: 0px !important;
    /* margin-left: 20px; */
    box-shadow: 0 10px 40px 0 rgba(18,106,211,.01), 0 2px 9px 0 rgba(18,106,211,.01);
}

.warningCard .quickLink-badge-text {
    background: #fbe8d4;
}

.successCard .quickLink-badge-text {
    background: #d5fbeb;
}
.successCard .quickLink-badge-text i {
    background: #00C671;
}

.primaryCard .quickLink-badge-text {
    background: #c3dcfd;
}
.primaryCard .quickLink-badge-text i {
    background: #1a73e8;
}


.quickLink-badge-text {
    background: #c3dcfd;
    display: inline-block !important;
    height: 30px;
    width: auto !important;
    line-height: 30px;
    margin-bottom: 10px;
    font-size: 12px;
    padding: 0px 10px;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
 

}

.quickLink-badge-text b {
    display: inline-block;
    font-weight: normal;
    margin-left: 17px;
    color: #242e4c;
}

.warningCard .quickLink-badge-text i {
    background: #F08D20;
}

.quickLink-badge-text i {
    margin-top: 10px;
    width: 10px;
    height: 10px;
 
    position: absolute;
    border-radius: 100%;
}

.quickLink-card-desc {
    font-size: 15px;
    display: block;
    color: #242e4c;
    font-weight: 600;
}


.quickLinkCard a {
    display: inline-block;
    margin-top: 20px;
    color: #242e4c;
    text-decoration: none;
    position: relative;
    cursor: pointer;
}

.arrowss:after {
    content: '';
    background: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20width%3D%2213%22%20height%3D%227%22%20viewBox%3D%220%200%2013%207%22%3E%3Cdefs%3E%3Cpath%20id%3D%22a%22%20d%3D%22M9.685%200L8.97.694l2.184%202.309H0v1.009h11.135L8.97%206.306%209.685%207%2013%203.5z%22/%3E%3C/defs%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cmask%20id%3D%22b%22%20fill%3D%22%23fff%22%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22/%3E%3C/mask%3E%3Cg%20fill%3D%22%239DB8FA%22%20mask%3D%22url%28%23b%29%22%3E%3Cpath%20d%3D%22M0-3h14v14H0z%22/%3E%3C/g%3E%3C/g%3E%3C/svg%3E);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    position: absolute;
    height: 7px;
    width: 13px;
    margin: -3px 0 0 !important;
    top: 50%;
    right: -30px;
    opacity: 1;
    line-height: 22px;
    line-height: 1.5714285714rem;
    font-size: 14px;
    font-size: 1rem;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}


/* slick slider styles */

.slick-nexts  {
    background: #fff;
    position: absolute;
    right: 20px;
    z-index: 6;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    bottom: 37%;
    box-shadow: 0 4px 20px 0 rgba(18, 106, 211, .07), 0 2px 9px 0 rgba(18, 106, 211, .06);
    padding: 5px;
    cursor: pointer;
    line-height: 38px;
    text-align: center;
    opacity: .7;
    transition: all 900ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.slick-next:hover{
    opacity: 1;
}

.slick-prevs {
    background: #fff;
    position: absolute;
    left: 20px;
    z-index: 6;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    bottom: 37%;
    box-shadow: 0 4px 20px 0 rgba(18, 106, 211, .07), 0 2px 9px 0 rgba(18, 106, 211, .06);
    padding: 5px;
    cursor: pointer;
    line-height: 38px;
    text-align: center;
    opacity: .7;
    transition: all 900ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.slick-next:hover{
    opacity: 1;
}

.slick-next:before {
    /* content: '→'; */
}

.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 30px;
    line-height: 1;
    opacity: 1;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.statistics-row{
    margin-bottom: 20px;
}

.bg-white-shadow {
    background: #fff;
    box-shadow: 0 4px 20px 0 rgba(18, 106, 211, .07), 0 2px 9px 0 rgba(18, 106, 211, .06);
    padding-bottom: 40px;
    border-radius: 2px;
    margin-bottom: 10px;
}


.card-body {
    /* -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem; */
}

.icNewDripper {
    background: #e3e6f9 !important;
    padding-top: 0 !important;
    line-height: normal !important;
    position: relative !important;
    z-index: 1;
    float: left !important;
    margin-right: 20px;
}


.icon-rounded {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    text-align: center;
}

.icon-rounded i {
    font-size: 24px;
    padding-top: 12px;
}

.icdefcc {
    color: rgb(120, 141, 180);
}

.icon {
    display: inline-block;
}

.icNewDripper:after {
    position: absolute;
    content: '';
    width: 70px;
    height: 70px;
    border-radius: 50%;
    bottom: 0;
    top: -10px;
    right: 0;
    left: -10px;
    background: rgba(235, 237, 252, 0.4);
    z-index: -1;
}

.mlweighless {
    font-weight: 500 !important;
    margin-top: 10px;
}

.lgcounter {
    font-size: 30px;
    font-weight: 700 !important;
    color: #242e4c;
}

.progress {
    cursor: progress;
}
.progress {
    background-color: #f0f6ff;
}

.fs-12 {
    font-size: 12px !important;
margin-top: 8px;
margin-bottom: 3px;
font-weight: 600;
}

.counter{
    /* font-size: 12px !important;
    margin-top: 5px;
    margin-bottom: 3px;  */
}


.btn {

  
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.ripple-btn{
    line-height: 2.1;
    margin: 2px;
}

.btn-primary.glow {
    box-shadow: 0 2px 4px 0 rgba(90,141,238,.5)!important;
}

.btn__icon_drip {
    position: absolute;
    right: 10px;
    line-height: 2;
    background: transparent;
    width: 20px;
    height: 20px;
    /* top: 20px; */
}

.btn .dripcons-effect {
    border-radius: 100%;
    -webkit-animation: ripple 0.6s linear infinite;
  
    animation: ripple 0.6s linear infinite;
}
.btn .dripcons-effect {
    border-radius: 100%;
    -webkit-animation: ripple 0.6s linear infinite;
    animation: ripple 0.6s linear infinite;
}
@-webkit-keyframes ripple {
    0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
}
100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
}
}
@keyframes ripple {
    0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
}
100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
}
}



.wallet-row{
    background-color: #fff;
    /* padding: 20px 30px; */
    margin-bottom: 30px;

    
}
.wallet-title{font-weight: 600; font-size: 1.4em;}
.wallet-title i {font-size: .8em; margin-right: 10px; }
.balance-row{margin: 20px 0px;}
.balance-amount{}
.balance-currency{font-weight: 500;}
.balance-value{    font-size: 2.5rem; font-weight: 600;    line-height: 1.2;}
.divider{    margin: 17px 0 !important;}
.balance-stats-header{font-weight: 500; color: #212121 !important; margin-bottom: 8px;}

.media{
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.icon-bar {
    margin-right: 1rem!important;

}

.icon-bar svg {
    color: #d3e0ed !important;
}
.stats-counter{margin-right: 2px; font-weight: 500;}
.stats-name{    color: #242e4c; font-weight: 600; font-size: 0.9em;}
.form-error-msg {
  font-size: 1em;
  margin-top: 3px;
  color: #fd397a;
}

/*-------------------------------------------------------------------*/
/* === Landing screens === */
/* Auth */
/* .auth .login-half-bg {
  background: url("../images/auth/login-bg.jpg");
  background-size: cover;
}

.auth .register-half-bg {
  background: url("../images/auth/register-bg.jpg");
  background-size: cover;
}

.auth.lock-full-bg {
  background: url("../images/auth/lockscreen-bg.jpg");
  background-size: cover;
} */

.auth .lock-profile-img {
  width: 90px;
  height: 90px;
  border-radius: 100%;
}

.auth .auth-form-light {
  background: #ffffff;
  margin-top: 140px;
  border-bottom: 1px solid #ebedf2;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
}

.auth .auth-form-light select {
  color: #c9c8c8;
}

.auth form .form-group .form-control {
  border-color: #c9ccd7;
  border-radius: 0px !important;
}

.auth .auth-form-light .input-group .form-control:focus,
.auth .auth-form-light .input-group .form-control:active {
  /* border-color: #c9ccd7; */
  border-color: #c9ccd7;
  /* border-color: red; */
}

.auth .auth-form-transparent {
  background: transparent;
}

.auth .auth-form-light .input-group-text {
  border-color: #c9ccd7;
  border-radius: 0px !important;
}

.auth .auth-form-transparent .form-control,
.auth .auth-form-transparent .input-group-text {
  /* border-color: #a3a4a5; */
  border-color: #c9ccd7;
}

.auth .auth-form-transparent .form-control:focus,
.auth .auth-form-transparent .form-control:active,
.auth .auth-form-transparent .input-group-text:focus,
.auth .auth-form-transparent .input-group-text:active {
  border-color: #c9ccd7;
  border-color: #c9ccd7;
}

.auth .auth-form-transparent select {
  outline-color: #a3a4a5;
}

.auth.auth-img-bg {
  padding: 0;
}

@media (min-width: 768px) {
  .auth.auth-img-bg .auth-form-transparent {
    width: 55%;
    margin: auto;
  }
}

.auth .brand-logo {
  margin-bottom: 2rem;
}

.auth .brand-logo img {
  width: 150px;
}

.auth form .form-group {
  margin-bottom: 1.5rem;
}

.auth form .form-group label {
  font-size: 0.8125rem;
}

.auth form .form-group .form-control {
  background: transparent;
  border-radius: 0;
  font-size: 1.2em;
}

.auth form .auth-form-btn {
  padding: 1rem 3rem;
  line-height: 1.5;
}

.auth form .auth-link {
  font-size: 1.1em;
  font-weight: 500;
}

.auth form .auth-link:hover {
  color: initial;
}

body {
  color: #4a5669 !important;
}

table {
  border-collapse: collapse;
  overflow: hidden;
  min-height: 300px;
  width: 100%;
  max-width: 100%;
  white-space: nowrap !important;
  table-layout: auto !important;
}
thead {
  /* visibility: visible;
  display: block;
  position: relative; */

  /* display: block; */
  overflow: hidden;
  text-overflow: ellipsis;

  vertical-align: middle;
  cursor: pointer;
  padding: 16px 10px;
  font-size: 1rem;
}
thead th {
  font-size: 1.1em;
  font-weight: 600;
  color: #6c7293;
}

thead tr {
  border-bottom: 1px solid #f0f3ff;
}

td {
  transition: background 0.3s ease;
  vertical-align: middle;
  padding: 10px !important;
  font-size: 1em;
  color: #6c7293;
  /* display: block; */
  overflow: hidden;
  text-overflow: ellipsis;
}

/* STYLE THE SEARCH BUTTON */

.table {
  margin-bottom: 30px !important;
  font-size: 14px !important;
  background-color: transparent !important;
  border-collapse: collapse !important;
  border-spacing: 0 !important;
  border: 0 !important;
}

.table > thead {
  border: 1px solid #e5efff !important;
  border: 0 !important;
  font-family: "Lota", sans-serif;
}

.table > thead > tr > th {
  color: #3e4755 !important;
  font-weight: 700 !important;
  vertical-align: middle !important;
  border: 0 !important;

  min-width: 10px !important;
}
.dx-g-bs4-toolbar {
  background: #fff !important;
  border: 0 !important;
}
.dx-g-bs4-toolbar input {
  border-color: #ddd;
  border-radius: 0;
  height: 34px;
  padding-left: 28px;
  box-shadow: none !important;
  border-width: 0 0 1px 0;
  background: transparent !important;
}

.table > thead {
  font-family: Lota, sans-serif !important;
  border-width: 1px !important;
  border-style: solid !important;
  /* border-color: rgb(229, 239, 255) !important; */
  border-image: initial !important;

  border: none !important;
}

.table > thead > tr > th {
  font-weight: 600 !important;
  vertical-align: middle !important;
  border-width: 0px !important;
  border-style: initial;
  border-color: initial;
  border-image: initial;
}

.table > tbody > tr:hover {
  /* background-color: #eef4ff !important; */
}

.table > tbody > tr:first-of-type {
  /* box-shadow: inset 0 8px 8px -7px rgba(150, 196, 255, 0.3) !important; */
}

.table > tbody > tr:nth-of-type(odd) {
  /* background-color: #fafbff !important; */
  border-bottom: 1px solid #f0f3ff;
}
.table > tbody > tr > td {
  color: #687992 !important;

  border-bottom: 1px solid #f0f3ff;

  /* border-bottom: 1px solid #f0f3ff !important; */
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: 0 !important;
}
.table > tbody > tr > td,
.table > tbody > tr > th {
  border-top: 0 !important;
  vertical-align: middle !important;
}

.table > tbody > tr > td {
  word-break: keep-all !important;
  white-space: pre-line !important;
}

.table > tbody > tr > td {
  color: #6c7293;
  cursor: pointer !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent !important;
}
.table > tbody > tr {
  transition: all 0.25s ease !important;
}
.table > tbody > tr:nth-of-type(odd) {
  /* background-color: #fafbff !important; */
  /* border-bottom: 1px solid #f0f3ff; */
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: transparent !important;
  border-top: 0 !important;
}

.table > tbody > tr > td:first-child {
  padding-left: 30px !important;
}

.table > thead > tr > th:first-child {
  padding-left: 30px !important;
}

.dx-rg-bs4-table-header-title {
  color: #6c7293 !important;
}

.dx-g-bs4-toolbar button.btn-outline-secondary {
  margin-right: 20px;
  padding: 8px;
  background-color: transparent;
  border: 1px solid #ebedf2 !important;
  color: #6c7293 !important;

  /* width: 120px; */
  /* height: 25px; */
}

.dx-g-bs4-toolbar button.btn-outline-secondary:hover,
.dx-g-bs4-toolbar button.btn-outline-secondary:active,
.dx-g-bs4-toolbar button.btn-outline-secondary:focus {
  color: #6c7293;
  border-color: #ebedf2 !important;
  background-color: #f4f5f8 !important;
}
.dx-g-bs4-toolbar button.btn-outline-secondary span::before {
  /* font-family: "Font Awesome 5 Free";
  font-weight: 900; */

  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0e7";
  margin-right: 6px;
  /* line-height: 20px; */
}

.dx-g-bs4-toolbar button.btn-outline-secondary span::after {
  content: "Export To CSV";
}
